*, :before, :after {
  box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity) );
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.mr-auto {
  margin-right: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-12 {
  height: 3rem;
}

.h-auto {
  height: auto;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-12 {
  width: 3rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x) ) translateY(var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse) );
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-current {
  border-color: currentColor;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity) );
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity) );
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity) );
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity) );
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity) );
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity) );
}

.p-4 {
  padding: 1rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-24 {
  padding-top: 6rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity) );
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity) );
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity) );
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity) );
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity) );
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity) );
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity) );
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity) );
}

.opacity-25 {
  opacity: .25;
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
}

*, :before, :after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

@media (min-width: 640px) {
  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }
}

@media (min-width: 768px) {
  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:flex-1 {
    flex: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1280px) {
  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:w-4\/12 {
    width: 33.3333%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }
}

/*# sourceMappingURL=about.755fdb1e.css.map */
